import React from 'react'
import { BsLinkedin, BsTwitter } from 'react-icons/bs'
import { SiDiscord } from 'react-icons/si'
import { FaTelegram } from 'react-icons/fa'
import {images, links} from '../../constants'

import './Footer.scss'

function Footer() {

const resourcesData = [
  {
    name:'Documentation',
    href:links.docs,
  },
  {
    name:"White Paper",
    href:links.whitePaper,
  }]

const ButtonMailto = ({ email, subject, body, className, ...props }) => {
    return (
      <a className={className} href={`mailto:${email}?subject=${subject || ""}&body=${body || ""}`}>
      {props.children}
      </a>
    );
};

  return (
    <footer className='app__footer-container' id="Contact">
      <div className='app__footer-main'>
        <div className='app__footer-border'>
          <div className='app__footer-main-section'>
            <div className='logo-container'>
              <img src={images.mq_logo} alt =""/>
              <h2>MetaQuants</h2>
            </div>
            <div className="app__footer-social-links">
              <BsLinkedin onClick={() => window.open(links.linkedIn)}/>
              <BsTwitter onClick={() => window.open(links.twitter)}/>
              <SiDiscord onClick={() => window.open(links.discord)}/>
              <FaTelegram onClick={() => window.open(links.telegram)}/>
            </div>
          </div>
          <div className='app__footer-list-container'>
            <div className='app__footer-list'>
              <ul>
                <h2>Home Page</h2>
                {['Home', 'Services', "Use Cases", 'Team', "Partners", "Contact"].map((item, index)=>(
                  <li key={index}>
                    <a href={`#${item}`} className="app__navbar-text">{item}</a>
                  </li> 
                ))}
              </ul>
            </div>
            <div className='app__footer-list'>
              <ul>
                <h2>Resources</h2>
                {resourcesData.map((item, index)=>(
                  <li key={index}>
                    <a href={item.href} target='_blank' className="app__navbar-text">{item.name}</a>
                  </li> 
                ))}
              </ul>
            </div>
            <div className='app__footer-list'>
              <ul>
                <h2>Contacts</h2>
                  <li>
                    <ButtonMailto className="app__navbar-text" email="info@metaquants.ai">
                      info@metaquants.ai
                    </ButtonMailto>
                  </li> 
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className='copyright'>
        <div className='app__footer-separator'/>
        <p>&copy;{new Date().getFullYear()} All rights reserved.</p>
      </div>
    </footer>
  )
}

export default Footer

// &copy;{new Date().getFullYear()} All rights reserved.