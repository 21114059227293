import images from "../constants/images";

const data = [
  {
    title: "GENERATE ALPHA",
    label: 4,
    description:
      "Private Investors and NFT funds need enterprise-grade analytics to maximize their investment returns. Our NFT Fi and valuation tools provide them with all the necessary information to generate alpha, support their strategies and monitor their results.",
    image: images.generateAlpha,
    imagelink: undefined,
  },
  {
    title: "WALLET INTEGRATIONS",
    label: 2,
    description:
      "MetaQuants can empower the analytical services offered by custodial or non-custodial wallets. In particular, institutions and web3 companies can seamlessly integrate customizable NFT analytics within their products.",
    image: images.walletIntegrations,
    imagelink: undefined,
  },
  {
    title: "DERIVATIVES & INDICES",
    label: 3,
    description:
      "The problem of price discovery remains a headwind for those who want to build advanced NFT products. Whether it be a perpetual DEX, an NFT index or a fund, real time appraisals and collection floor prices are required as a backbone for them. ",
    image: images.indices,
    imagelink: undefined,
  },
  {
    title: "NFT LENDING",
    label: 1,
    description:
      "P2Peer and P2Pool NFT lending protocols are a new vertical propelled by the financialization of NFTs. Imagine a borrower willing to pledge his rare Azuki valued at 70 ETH, but is offered 20% LTV of the floor price that stands at 10 ETH. Capital inefficiency and the risk of liquidation remain a problem for the borrower.",
    image: images.nftBorrowing,
    imagelink: undefined,
  },
];

export default data;
