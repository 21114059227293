import React from 'react'

import './Team.scss'
import {AnimatedDiv} from '../../components/Animated'
import data from '../../data/team'

function Team() {

  return (
    <div className='app__constrain' id="Team">
      <div className='app__container-team'>
        <div className="app__team">
          
          {/* Text */}
          <AnimatedDiv
          initial={{ opacity: 0, translateY:-60 }}
          whileInView={{opacity:1, translateY:0}}
          transition={{duration:0.4}}
          className="app_head-text"
          >
            <h2 className="head-text">Team</h2>
            <div className="separator"/>
          </AnimatedDiv>

          {/* Card */}
          <div className='app__team-cards-container'>
          {data.sort((a, b) => a.label - b.label).map((data,index) =>(
            <AnimatedDiv
            initial={{ opacity: 0, translateY:60 }}
            whileInView={{opacity:1, translateY:0}}
            transition={{duration:0.4, delay:0.2*index}}
            className='app__team-card'
            key={data.title + index}
            >
              <div className='app__team-card-logo'>
                <a href={data.socialMediaLink} target='_blank'>
                {
                  data.image ? (<img src={data.image} alt={data.name}/>) : 
                  (<img src={data.imagelink} alt={data.title}/>)
                }
                </a>
              </div>
              <h2>{data.name}</h2>
              <h3>{data.title}</h3>
              <p>{data.description}</p>
            </AnimatedDiv>
          ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Team