import images from "../constants/images";

const data = [
  {
    name: "Nexo",
    label: 1,
    websiteUrl: "https://nexo.io/",
    image: undefined,
    imageUrl:
      "https://nexo.careers/media/site/98838f61e2-1648140618/logo-white.svg",
  },
  {
    name: "Zash",
    label: 2,
    websiteUrl: "https://www.zashfinance.xyz/",
    image: undefined,
    imageUrl:
      "https://uploads-ssl.webflow.com/620e57315cd5bf8aa8c47941/626be23bf8fa4de08225b1cb_zash_logo_white_nobg%20(3).png",
  },
  {
    name: "Api3",
    label: 3,
    websiteUrl: "https://api3.org/",
    image: images.api3,
    imageUrl: undefined,
  },
  {
    name: "Campus Fund",
    label: 4,
    websiteUrl: "https://campusfund.ch/",
    image: images.campus_fund,
    imageUrl: undefined,
  },
  {
    name: "Polygon",
    label: 5,
    websiteUrl: "https://polygon.technology/",
    image: images.polygon,
    imageUrl: undefined,
  },
  {
    name: "EZswap",
    label: 6,
    websiteUrl: "https://ezswap.io/#/index",
    image: images.ezswap,
    imageUrl: undefined,
  },
  {
    name: "Protecc NFT",
    label: 7,
    websiteUrl: undefined,
    image: images.protecc_nft,
    imageUrl: undefined,
  },
  {
    name: "Tribe 3",
    label: 8,
    websiteUrl: "https://tribe3.xyz/",
    image: images.tribe_3,
    imageUrl: undefined,
  },
  {
    name: "Pine",
    label: 9,
    websiteUrl: "https://pine.loans/",
    image: images.pine,
    imageUrl: undefined,
  },
  {
    name: "Soduim",
    label: 10,
    websiteUrl: "https://sodium.fi/",
    image: images.sodium,
    imageUrl: undefined,
  },
  {
    name: "Skillet",
    label: 11,
    websiteUrl: "https://skillet.ai/",
    image: images.skillet,
    imageUrl: undefined,
  },
  {
    name: "Wasabi",
    label: 11,
    websiteUrl: "https://www.wasabi.xyz/home",
    image: images.wasabi,
    imageUrl: undefined,
  },
  {
    name: "Astaria",
    label: 12,
    websiteUrl: "https://astaria.xyz/",
    image: images.astaria,
    imageUrl: undefined,
  },
  {
    name: "Opensky",
    label: 11,
    websiteUrl: "https://opensky.finance/",
    image: images.opensky,
    imageUrl: undefined,
  },
];

export default data;
