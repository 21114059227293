import React, {
  useState,
  Suspense,
  lazy,
} from "react";
import "./Header.scss";
import {
  ToastContainer,
  toast,
} from "react-toastify";
import { links } from "../../constants";
import "react-toastify/dist/ReactToastify.css";
import { AnimatedInput } from "../../components/Animated";
import useMatchMedia from "../../components/ScreenSize";
const Spline = lazy(() =>
  import("@splinetool/react-spline")
);

function Header() {
  const [email, setEmail] = useState("");
  const isDesktopResolution = useMatchMedia(
    "(min-width:700px)",
    true
  );

  const toastSetup = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();

    if (!email) {
      toast.error("Email Required", toastSetup);
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
        email
      )
    ) {
      toast.warn(
        "Invalid email address",
        toastSetup
      );
    } else {
      toast.success(
        `Submitting Name ${email}`,
        toastSetup
      );

      fetch(
        "https://dvvfku6zp0.execute-api.eu-central-1.amazonaws.com/PROD?fbclid=IwAR2K92LLHEar3OJCKx_t1oBrQBy0zSY6bD2u_8DnXSxkZ9focBJLfQpAiiI",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          // We convert the React state to JSON and send it as the POST body
          body: JSON.stringify({ email: email }),
        }
      ).then(function (response) {
        //console.log(response)
        return response.json();
      });
      setEmail("");
    }
  };

  return (
    <div className="app__constrain" id="Home">
      <div className="app__container-header app__flex">
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        <div className="app__header">
          <div className="app__header-text">
            <h1>
              <span>MetaQuants</span> is the
              analytical infrastructure for{" "}
              <span> NFT Fi.</span> Powered by an
              AI-driven terminal.
            </h1>
            <p>
              Do not be a spectator of NFT
              financialization. Join our waitlist.
            </p>
            <form onSubmit={handleSubmit}>
              <label>
                <input
                  type="email"
                  name="name"
                  placeholder="Enter your Email..."
                  autoComplete="off"
                  className="app__header-email-input"
                  value={email}
                  onChange={(e) =>
                    setEmail(e.target.value)
                  }
                />
              </label>
              <AnimatedInput
                type="submit"
                value="Submit"
                className="app__header-button app__header-text"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              />
            </form>
          </div>
        </div>
        {isDesktopResolution && (
          <div className="background-3d">
            <Suspense fallback={<div />}>
              <Spline scene={links.spline} />
            </Suspense>
          </div>
        )}
      </div>
    </div>
  );
}

export default Header;
