import React from 'react'
import './Services.scss'
import {AnimatedDiv} from '../../components/Animated'
import data from '../../data/services'

function Services() {
  return (
    <div className='app__constrain' id="Services">
      <div className='app__container-services'>
        <div className="app__services">
          
          {/* Text */}
          <AnimatedDiv 
          initial={{ opacity: 0, translateY:-60 }}
          whileInView={{opacity:1, translateY:0}}
          transition={{duration:0.4}}
          className="app_head-text"
          >
            <h2 className="head-text">Solutions</h2>
            <div className="separator"/>
          </AnimatedDiv>

          {/* Card */}
          <div className='app__services-cards-container'>
          {data.sort((a, b) => a.label - b.label).map((data,index) =>(
              <AnimatedDiv
              initial={{ opacity: 0, translateY:60 }}
              whileInView={{opacity:1, translateY:0}}
              transition={{duration:0.4, delay:0.2*index}}
              className='app__services-card'
              key={data.title + index}
              >
                <div className='app__services-card-logo'>
                  {
                    data.image ? (<img src={data.image} alt={data.title}/>) : (<img src={data.imagelink} alt={data.title}/>)
                  }
                </div>
                <div className='card-color-item-1'/>
                <h2>{data.title}</h2>
                <p>{data.description}</p>
              </AnimatedDiv>
          ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Services