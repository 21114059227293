import React, {Suspense, lazy} from 'react'

const MotionDiv = lazy(()=>
    import("framer-motion").then(mod=>({
        default:mod.motion.div
    }))
)

export const AnimatedDiv = props => (
    <Suspense fallback={<div className={props.className}>{props.children}</div>}>
        <MotionDiv {...props}/>
    </Suspense>
)

const MotionInput= lazy(()=>
    import("framer-motion").then(mod=>({
        default:mod.motion.input
    }))
)

export const AnimatedInput = props => (
    <Suspense fallback={<input type={props.type} value={props.value} className={props.className}/>}>
        <MotionInput {...props}/>
    </Suspense>
)