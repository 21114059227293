import React,{Suspense, lazy} from 'react'

import {Header} from './container'
import {NavBar} from './components'
import './App.scss'

const Services = lazy(() => import('./container/Services/Services'));
const UseCases = lazy(() => import('./container/UseCases/UseCases'));
const Team = lazy(() => import('./container/Team/Team'));
const Partners = lazy(() => import('./container/Partners/Partners'));
const Footer = lazy(() => import('./container/Footer/Footer'));


function App() {
  return (
    <div className='app'>
        <NavBar />
        <Header />
        <Suspense fallback={<div/>}>
          <Services />
        </Suspense>

        <Suspense fallback={<div/>}>
          <UseCases />
        </Suspense>

        <Suspense fallback={<div/>}>
          <Team />
        </Suspense>

        <Suspense fallback={<div/>}>
          <Partners/>
        </Suspense>

        <Suspense fallback={<div/>}>
          <Footer />
        </Suspense>
    </div>
  )
}

export default App