import React, {useState} from 'react'
import './NavBar.scss'

import SocialMediaIcons from '../SocialMediaIcons/SocialMediaIcons'
import { BsLinkedin, BsTwitter } from 'react-icons/bs'
import { FaTelegram } from 'react-icons/fa'
import { SiDiscord } from 'react-icons/si'
import {images, links} from "../../constants"
import { HiMenuAlt4, HiX} from 'react-icons/hi'
import {AnimatedDiv} from '../Animated'

function NavBar() {
  const [toggle, setToggle] = useState(false)
  const variants = {
    open: {opacity: 1, y:0},
    closed: {opacity: 0, y:'-100%'}
  }


const LaunchButton = () => {
  return (
    <a href={links.app} target='_blank'>
    <AnimatedDiv 
    whileHover={{scale:1.1}}
    whileTap={{ scale: 0.9 }}
    className='launch-button'>Launch App    
    </AnimatedDiv>
    </a>
    )
}

const navTabs = [
    {
    name: "Home",
    href: "#Home",
    },
    {
    name: "Documentation",
    href: links.docs,
    },
    {
    name: "Blog",
    href: links.medium,
    },
  ]
//['Home', 'Services', "Use Cases", 'Team', "Partners", "Contact"]
  return (
    <div className='app__constrain'>
      <nav className='app__navbar'>
          {/* Logo */}
          <div className='app__navbar-logo'>
            <a href={`#Home`}>
              <img src={images.mq_logo} alt="logo"/>
            </a>
          </div>

          {/* Links */}
          <ul className='app__navbar-links'>
              {navTabs.map((item, i)=>(
                <li className='app__flex' key={`link-${i}`}>
                  {/* <div/> */}
                  <a href={item.href} className="app__navbar-text">{item.name}</a>
                </li>  
              ))}
          </ul>
          
          {/* Icons */}
          <SocialMediaIcons/>
          <LaunchButton/>

          {/* Foldable Menu */}
          <div className='app__navbar-menu' onClick={()=>setToggle(true)}>
            <HiMenuAlt4/>
          </div>
          <AnimatedDiv
              initial={{opacity: 0, y:'-100%'}}
              animate={toggle ? "open" : "closed"}
              variants={variants}
              transition={{duration:0.8, ease: "easeInOut"}}
              className='app__navbar-down-menu'
          >
            <HiX onClick={()=>setToggle(false)}/>
            <ul>
              {navTabs.map((item,i)=>(
                <li key={i}>
                  <a href={item.href} className="app__navbar-text" onClick={()=>setToggle(false)}>{item.name}</a>
                </li> 
              ))}
              <div className='app__navbar-menu-links'>
                <BsLinkedin onClick={() => window.open(links.linkedIn)}/>
                <BsTwitter onClick={() => window.open(links.twitter)}/>
                <SiDiscord onClick={() => window.open(links.discord)}/>
                <FaTelegram onClick={() => window.open(links.telegram)}/>
              </div>
            
                <button className='down-menu-launch-button' onClick={() => window.open(links.app)}>Launch App</button>
      
            </ul>
          </AnimatedDiv>
      </nav>
    </div>
  )
}

export default NavBar