import images from "../constants/images";

const data = [
  {
    title: "NFT Oracles",
    label: 1,
    description:
      "Our flagship products include an NFT Pricing Algorithm and a Collection Floor Price Oracle. Leverage our institutional grade infrastructure for accurate point estimates and streaming floor prices of Blue Chip collections in real-time.",
    image: images.nftPricing,
    imagelink: undefined,
  },
  {
    title: "MQ Terminal",
    label: 2,
    description:
      "An AI-powered terminal to address all aspects of NFT finance. Offering pro trading and risk management tools, protocol integrations, and a seamless experience for traders, lenders and institutional investors.",
    image: images.analyticsAndRisk,
    imagelink: undefined,
  },
  {
    title: "NFT Fi Tools",
    label: 3,
    description:
      "Whether you want to track NFT strategies of a specific address, or preview the NFT lending landscape. We are building the ultimate aggregator of financialized data for NFT protocols and wallets, complemented by a credit risk scorer.",
    image: images.settings,
    imagelink: undefined,
  },
];

export default data;
