import React from 'react'
import { BsLinkedin, BsTwitter } from 'react-icons/bs'
import { SiDiscord } from 'react-icons/si'
import { FaTelegramPlane } from 'react-icons/fa'
import { links } from '../../constants'
import { AnimatedDiv } from '../Animated'
import './SocialMediaIcons.scss'

function SocialMediaIcons() {
  return (
    <div className='app__navbar-icons'>
    <a href={links.linkedIn} target='_blank'>
    <AnimatedDiv 
    whileHover={{scale:1.1}}
    whileTap={{ scale: 0.9 }}
    className='icons-item'
    >
      <BsLinkedin/>
    </AnimatedDiv>
    </a>

    <a href={links.twitter} target='_blank'>
    <AnimatedDiv 
    whileHover={{scale:1.1}}
    whileTap={{ scale: 0.9 }}
    className='icons-item'
    
    >
      <BsTwitter/>
    </AnimatedDiv>
    </a>

    <a href={links.discord} target='_blank'>
    <AnimatedDiv 
    whileHover={{scale:1.1}}
    whileTap={{ scale: 0.9 }}
    className='icons-item'
    
    >
      <SiDiscord/>
    </AnimatedDiv>
    </a>

    <a href={links.telegram} target='_blank'>
    <AnimatedDiv 
    whileHover={{scale:1.1}}
    whileTap={{ scale: 0.9 }}
    className='icons-item'
    
    >
      <FaTelegramPlane/>
    </AnimatedDiv>
    </a>
  </div>
  )
}

export default SocialMediaIcons