import React from 'react'

import {AnimatedDiv} from '../../components/Animated'
import './UseCases.scss'
import data from '../../data/useCases'


function UseCases() {
  return (
    <div className='app__constrain' id="Use Cases">
      <div id="Use Cases" className='app__container-usecases'>
        <div className="app__usecases">
          
          {/* Text */}
          <AnimatedDiv 
          initial={{ opacity: 0, translateY:-60 }}
          whileInView={{opacity:1, translateY:0}}
          transition={{duration:0.4}}
          className="app_head-text"
          >
            <h2 className="head-text">Use Cases</h2>
            <div className="separator"/>
          </AnimatedDiv>

          {/* Card */}
          <div className='app__usecases-cards-container'>
          {data.sort((a, b) => a.label - b.label).map((data,index) =>(
            <AnimatedDiv
            initial={{ opacity: 0, translateY:60 }}
            whileInView={{opacity:1, translateY:0}}
            transition={{duration:0.4, delay:0.2*index}}
            className='app__usecases-card'
            key={data.title + index}
            >
              <div className='app__usecases-card-logo'>
                {
                  data.image ? (<img src={data.image} alt={data.title}/>) : (<img src={data.imagelink} alt={data.name}/>)
                }
              </div>
              <h2>{data.title}</h2>
              <p>{data.description}</p>
            </AnimatedDiv>
          ))}
          </div>
        </div>
      </div>
    </div>
  )
}


export default UseCases