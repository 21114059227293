import React from 'react'
import { AnimatedDiv } from '../../components/Animated';

import data from '../../data/partners'

import './Partners.scss'


function Partners() {
    return (
      <div className='app__constrain' id="Partners" >
        <div className='app__container-partners'>
          <div className="app__partners">
            
            {/* Text */}
            <AnimatedDiv 
            initial={{ opacity: 0, translateY:-60 }}
            whileInView={{opacity:1, translateY:0}}
            transition={{duration:0.4}}
            className="app_head-text"
            >
              <h2 className="head-text">Partners</h2>
              <div className="separator"/>
            </AnimatedDiv>
    
            {/* Card */}
            <div className='app__partners-logo-container'>
            {data.sort((a, b) => a.label - b.label).map((data,index) =>(
              <AnimatedDiv
              initial={{ opacity: 0, translateY:60 }}
              whileInView={{opacity:1, translateY:0}}
              transition={{duration:0.4}}
              whileHover={{scale:1.05}}
              className='app__partners-logo' 
              key={data.name + index}
              onClick={() => window.open(data.websiteUrl)}
              >
                {
                  data.image ? (<img src={data.image} alt={data.name}/>) : (<img src={data.imageUrl} alt={data.name}/>)
                }
              </AnimatedDiv>
            ))}
            </div>
          </div>
        </div>
      </div>
  )
}

export default Partners