const discord =
  "https://discord.com/invite/9eUr32kxmK";
const linkedIn =
  "https://www.linkedin.com/company/metaquantsai/";
const twitter =
  "https://twitter.com/metaquants_ai";
const whitePaper =
  "https://drive.google.com/file/d/19ZY5Flr-nkI3BTbyFkjX4lFP85G5qFPY/view?usp=sharing";
const docs = "https://docs.metaquants.xyz/";
const medium = "https://medium.com/@metaquants";
const telegram = "https://t.me/+r1PiFH2vMsg3YjQ0";

const spline =
  "https://prod.spline.design/fE9uUuXKpYL1N7yg/scene.splinecode";

const app = "https://www.terminal.metaquants.xyz/"

export default {
  discord,
  linkedIn,
  twitter,
  whitePaper,
  docs,
  spline,
  telegram,
  medium,
  app
};
