import images from "../constants/images";

const data = [
  {
    name: "Martin Palazov",
    label: 1,
    title: "Co-Founder",
    description:
      "Business Developer with expertise in the fields of DeFi, NFTs and advanced analytics.  Studied Economics, Management and Computer Science at Bocconi University and HSG.",
    image: images.mp,
    imagelink: undefined,
    socialMediaLink:
      "https://www.linkedin.com/in/martin-palazov/",
  },
  {
    name: "Georgi Angelchev",
    label: 2,
    title: "Co-Founder",
    description:
      "Machine Learning Engineer with hand-on experience in the domain of Regression Analysis and NLP.  Holds a degree from Bocconi University with focus on Data Science.",
    image: images.ga,
    imagelink: undefined,
    socialMediaLink:
      "https://www.linkedin.com/in/georgi-angelchev-08b50b196/",
  },
  {
    name: "Kaloyan Mitev",
    title: "Backend Developer",
    description:
      "A talented Software Engineer who has been building enterprise backend integrations with Java.   His background lies at the intersection of finance and technology.",
    image: images.km,
    imagelink: undefined,
    socialMediaLink: undefined,
  },
  {
    name: "Borislav Lorinkov",
    label: 4,
    title: "Frontend Engineer",
    description:
      "Creative and solutions oriented developer utilizing React JS and React Native.   Graduated with a degree in Mechatronics and Robotics from the University of Leeds.",
    image: images.bl,
    imagelink: undefined,
    socialMediaLink:
      "https://www.linkedin.com/in/borislav-lorinkov-724300232/",
  },
  {
    name: "Daniele Tonini",
    label: 5,
    title: "Analytics Advisor",
    description:
      "Academic Fellow at Bocconi University in the field of machine learning and big data.  He has deployed predictive systems and dynamic reporting tools for risk management and pricing applications for 15 years.",
    image: images.dt,
    imagelink: undefined,
    socialMediaLink:
      "https://www.linkedin.com/in/danieletonini/",
  },
  {
    name: "Kiril Nikolov",
    label: 6,
    title: "Strategic Advisor",
    description:
      "Strong sales and business development professional. Head of Strategy at Nexo.   He brings his expertise in building successful ventures at the intersection of DeFi and TradFi.",
    image: images.kn,
    imagelink: undefined,
    socialMediaLink:
      "https://www.linkedin.com/in/kirilnikolov1/",
  },
];

export default data;
