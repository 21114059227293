import mq_logo from "../assets/MetaQuantsLogo.png";
import algorithm from "../assets/algorithm.png";
import chart from "../assets/chart.png";
import connection from "../assets/connection.png";
import low_price from "../assets/low-price.png";
import neural from "../assets/neural.png";
import trade from "../assets/trade.png";
import analyticsAndRisk from "../assets/analytics&risk.png";
import bl from "../assets/wwwbl.jpg";
import mp from "../assets/mp.jpg";
import kn from "../assets/kn.jpg";
import dt from "../assets/dt.jfif";
import ga from "../assets/ga.jfif";
import km from "../assets/km.jfif";
import floorPrice from "../assets/floor-price.png";
import generateAlpha from "../assets/generate-alpha.png";
import indices from "../assets/indices.png";
import nftBorrowing from "../assets/nft-borrowing.png";
import nftPricing from "../assets/nft-pricing.png";
import walletIntegrations from "../assets/wallet-integrations.png";
import api3 from "../assets/api3.png";
import campus_fund from "../assets/campus-fund.png";
import polygon from "../assets/polygon-logo-white.svg";
import ezswap from "../assets/ezswap.svg";
import protecc_nft from "../assets/protecc_nft.png";
import tribe_3 from "../assets/tribe_3.svg";
import pine from "../assets/pine_loans.png";
import sodium from "../assets/sodium.svg";
import settings from "../assets/settings.png";
import skillet from "../assets/skillet.png";
import wasabi from "../assets/wasabi_logo.svg";
import astaria from "../assets/astaria.svg";
import opensky from "../assets/opensky.png";

export default {
  mq_logo,
  algorithm,
  chart,
  connection,
  low_price,
  neural,
  trade,
  analyticsAndRisk,
  bl,
  mp,
  kn,
  dt,
  ga,
  km,
  floorPrice,
  generateAlpha,
  indices,
  nftBorrowing,
  nftPricing,
  walletIntegrations,
  api3,
  polygon,
  campus_fund,
  ezswap,
  protecc_nft,
  tribe_3,
  pine,
  sodium,
  settings,
  skillet,
  wasabi,
  astaria,
  opensky,
};
